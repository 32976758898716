import React, { FC, useMemo } from "react";

import { graphql } from "gatsby";

import ReactMarkdown from "react-markdown";
import { formatDate } from "~utils";
import { PageLayout } from "~components/templates/page-layout";
import * as styles from "./cve.module.scss";

type Props = {
  data: GatsbyCvePageComponentQuery;
};

const Component: FC<Props & { className?: string }> = ({ data, className }) => {
  const date = useMemo(() => {
    if (data.contentfulCve?.date === undefined) {
      return undefined;
    }
    return formatDate(data.contentfulCve?.date, "YYYY/MM/DD");
  }, [data]);
  if (!data.contentfulCve) return null;
  const {
    cveId,
    credit,
    cwe,
    description,
    affectedVersions,
    productUrls,
    reference,
    titleEn,
    titleJp,
  } = data.contentfulCve;
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{
        title: `${cveId} | ${titleEn}`,
        description: `Detail about ${cveId}`,
      }}
    >
      <main className={className}>
        <div className={styles.CVEDetail_Wrapper}>
          <p className={styles.CVEDetail_Date}>
            <span className={styles.CVEDetail_DateOpening}>
              Disclosure Date
            </span>{" "}
            {date}
          </p>
          <h1 className={styles.CVEDetail_Id}>{cveId}</h1>
          <p className={styles.CVEDetail_TitleEn}>{titleEn}</p>
          <p className={styles.CVEDetail_TitleJp}>{titleJp}</p>
          {credit?.credit && (
            <div>
              <h2 className={styles.CVEDetail_Heading}>Credit</h2>
              <p className={styles.CVEDetail_Content}>
                {credit?.credit && (
                  <ReactMarkdown>{credit.credit}</ReactMarkdown>
                )}
              </p>
            </div>
          )}
          {affectedVersions?.affectedVersions && (
            <div>
              <h2 className={styles.CVEDetail_Heading}>Affected-Versions</h2>
              <p className={styles.CVEDetail_Content}>
                <ReactMarkdown>
                  {affectedVersions.affectedVersions}
                </ReactMarkdown>
              </p>
            </div>
          )}
          {cwe?.cwe && (
            <div>
              <h2 className={styles.CVEDetail_Heading}>CWE</h2>
              <p className={styles.CVEDetail_Content}>
                {cwe?.cwe && <ReactMarkdown>{cwe.cwe}</ReactMarkdown>}
              </p>
            </div>
          )}
          <h2 className={styles.CVEDetail_Heading}>Description</h2>
          <p className={styles.CVEDetail_Content}>
            {description?.description && (
              <ReactMarkdown>{description.description}</ReactMarkdown>
            )}
          </p>
          {productUrls?.productUrls && (
            <div>
              <h2 className={styles.CVEDetail_Heading}>Product-URLs</h2>
              <p className={styles.CVEDetail_Content}>
                {productUrls?.productUrls && (
                  <ReactMarkdown>{productUrls.productUrls}</ReactMarkdown>
                )}
              </p>
            </div>
          )}

          {reference?.reference && (
            <div>
              <h2 className={styles.CVEDetail_Heading}>Reference</h2>
              <p className={styles.CVEDetail_Content}>
                {reference?.reference && (
                  <ReactMarkdown>{reference?.reference}</ReactMarkdown>
                )}
              </p>
            </div>
          )}
        </div>
        <a className={styles.CVEDetail_BackLink} href="/cve">
          Back
        </a>
      </main>
    </PageLayout>
  );
};

export default Component;

export const query = graphql`
  query CVEPageComponent($cveId: String!) {
    contentfulCve(cveId: { eq: $cveId }) {
      date
      credit {
        credit
      }
      cveId
      cwe {
        cwe
      }
      description {
        description
      }
      affectedVersions {
        affectedVersions
      }
      productUrls {
        productUrls
      }
      reference {
        reference
      }
      titleEn
      titleJp
    }
  }
`;
